import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../partials/header"
import Footer from "../partials/footer"
import PaymentForm from "../components/payemntform"

const stripePromise = loadStripe(`${process.env.STRIPE_PRODUCTION_KEY}`);

const PaymentPage = () => {
    return (
        <main>
        <Header/>
        <div className="App">

            <div className="container pt-60 mb-30">
                <div className="row justify-content-center">
                     <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements>
                </div>
         </div>
        </div>
            <Footer/>
        </main>
    );
};

export default PaymentPage;